/* eslint-disable import/prefer-default-export */

/*
dichotomy_5
dichotomy_7
dichotomy_with_polar_affirmation
likert_6
likert_7
open
org_structure
nps
 */
export const matchQuestionTypesComponents = {
  dichotomy_5: 'question-dichotomy-5',
  dichotomy_7: 'question-dichotomy-7',
  dichotomy_with_polar_affirmation: 'question-dichotomy-with-polar-affirmation',
  likert_6: 'question-likert-6',
  likert_7: 'question-likert-7',
  open: 'question-open',
  // ToDo бэк просил пока не использовать тип вопроса орг_структура
  // org_structure: 'org_structure'
  nps: 'question-NPS',
  list: 'question-list',
  horizontal_block: 'question-horizontal',
  matrix: 'question-matrix',
  scale: 'question-scale',
};

export const blockTypes = {
  block: ['text', 'image', 'matrix'],
  question: ['plain', 'question'],
};

export const matchBlockTypesComponents = {
  image: 'image-block',
  text: 'text-block',
  matrix: 'matrix-block',
};

export const UserSurveyStatus = {
  IN_PROGRESS: 'in_progress',
  DONE: 'done',
};

export const SurveyStatus = {
  DRAFT: 'draft',
  ACTIVE: 'active',
  FINISHED: 'finished',
  PUBLISHED: 'published',
  TRASHED: 'trashed',
};

export const MOVE_TYPES = {
  FORWARD: 'forward',
  BACKWARD: 'backward',
};

export const LOCALES_LIST = [
  {
    name: 'Русский',
    short: 'ru',
    flag: 'ru_flag',
    title: 'Выберите язык',
  },
  {
    name: 'English',
    short: 'en',
    flag: 'en_flag',
    title: 'Choose your language',
  },
  {
    name: 'Francais',
    short: 'fr',
    flag: 'fr_flag',
    title: 'Sélectionnez la langue',
  },
  {
    name: 'Polski',
    short: 'pl',
    flag: 'pl_flag',
    title: 'Wybierz język',
  },
  {
    name: 'Latviesu',
    short: 'lv',
    flag: 'lv_flag',
    title: 'Izvēlaties valodu',
  },
  {
    name: 'Türk',
    short: 'tr',
    flag: 'tr_flag',
    title: 'Dil seçin',
  },
  {
    name: 'Қазақ',
    short: 'kz',
    flag: 'kz_flag',
    title: 'Тілді таңдаңыз',
  },
];

export const FEATURE_FLAGS = {
  'use-helpdeskeddy': 'use-helpdeskeddy',
};
