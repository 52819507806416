<template>
  <div>
    <VSelect
      :value="$i18n.locale"
      :items="preparedLanguages"
      :menu-props="{
        bottom : true,
        offsetY : true,
        nudgeBottom : '14px',
        nudgeLeft : '14px',
        minWidth : '112px'
      }"
      item-value="short"
      item-text="short"
      :ripple="false"
      hide-details
      class="locale-switcher"
      @change="switchLocale"
    >
      <template #prepend-inner>
        <VImg
          :src="require(`@/assets/img/${currentLocaleFlag}.svg`)"
          max-width="24"
          height="16"
          class="my-1 mr-1"
        />
      </template>

      <template #selection="{ item }">
        <div class="text-capitalize font-weight-medium">
          {{ item.short }}
        </div>
      </template>

      <template #item="{ item }">
        <div class="d-flex align-center">
          <VImg
            :src="require(`@/assets/img/${item.flag}.svg`)"
            max-width="24"
            height="16"
            class="mr-4"
          />

          <div class="text-capitalize">
            {{ item.short }}
          </div>
        </div>
      </template>
    </VSelect>
  </div>
</template>

<script>
import { LOCALES_LIST } from '@/utils/constants';
import { toggleLoading } from '@/plugins/vuex/mutationTypes';
import * as apiService from '@/services/api';
import errorHandler from '@/helpers/errorHandler';
import { mapMutations } from 'vuex';

export default {
  name: 'LanguageSwitcherMini',

  props: {
    languages: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      LOCALES_LIST,
    };
  },

  computed: {
    languagesShort() {
      return this.languages.map((lang) => lang.language);
    },
    preparedLanguages() {
      return this.LOCALES_LIST.filter((locale) => this.languagesShort.includes(locale.short));
    },
    currentLocaleFlag() {
      return this.LOCALES_LIST.find((locale) => locale.short === this.$i18n.locale).flag;
    },
  },

  methods: {
    ...mapMutations([toggleLoading]),
    async switchLocale(val) {
      if (this.$i18n.locale !== val) {
        this[toggleLoading](true);
        try {
          await apiService.surveyLanguageUpdate(this.$route.params.slug, {
            current_language: val,
          });
          this.$i18n.locale = val;
        } catch (err) {
          errorHandler(err);
        } finally {
          this[toggleLoading](false);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.locale-switcher {
  width: 112px;
  border-radius: 4px;
  padding: 10px 6px 10px 14px;
}

.locale-switcher.v-select--is-menu-active {
  background: #e6f6fe;
}
</style>
