var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('VSelect',{staticClass:"locale-switcher",attrs:{"value":_vm.$i18n.locale,"items":_vm.preparedLanguages,"menu-props":{
      bottom : true,
      offsetY : true,
      nudgeBottom : '14px',
      nudgeLeft : '14px',
      minWidth : '112px'
    },"item-value":"short","item-text":"short","ripple":false,"hide-details":""},on:{"change":_vm.switchLocale},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('VImg',{staticClass:"my-1 mr-1",attrs:{"src":require(`@/assets/img/${_vm.currentLocaleFlag}.svg`),"max-width":"24","height":"16"}})]},proxy:true},{key:"selection",fn:function({ item }){return [_c('div',{staticClass:"text-capitalize font-weight-medium"},[_vm._v(" "+_vm._s(item.short)+" ")])]}},{key:"item",fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center"},[_c('VImg',{staticClass:"mr-4",attrs:{"src":require(`@/assets/img/${item.flag}.svg`),"max-width":"24","height":"16"}}),_c('div',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(item.short)+" ")])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }